import Store from 'Store/store';
import MyWorkflows from 'Components/Vue-Routes/MyWorkflows';
import MyWorkflowsV2 from 'Components/Vue-Routes/MyWorkflowsV2';
import WorkflowView from 'Components/Vue-Routes/WorkflowsRedesign/WorkflowView';
import WorkflowViewV2 from 'Components/Vue-Routes/Workflows/WorkflowView';

export const workflowRoutes = [
  {
    path: '/workflow/:workflowID',
    name: 'workflow',
    component: WorkflowView,
    meta: {
      canAccess: () => !Store.getters.hasFeatureFlag('new_workflows'),
      accessRedirect: '/workflowV2/:workflowID',
      stylesheet: 'old',
    },
  },
  {
    path: '/workflowV2/:workflowID',
    name: 'workflowV2',
    component: WorkflowViewV2,
    meta: {
      canAccess: () => Store.getters.hasFeatureFlag('new_workflows') && Store.getters.canViewWorkflowDetails,
      stylesheet: 'old',
    },
  },
  {
    path: '/workflows',
    component: MyWorkflows,
    meta: {
      canAccess: () => !Store.getters.hasFeatureFlag('new_workflows'),
      accessRedirect: '/workflowsV2',
      stylesheet: 'old',
    },
  },
  {
    path: '/workflowsV2',
    component: MyWorkflowsV2,
    props: (route) => ({ ...route.query }),
    meta: {
      canAccess: () => Store.getters.hasFeatureFlag('new_workflows') && Store.getters.canViewWorkflowsDashboard,
      stylesheet: 'old',
    },
  },
];
