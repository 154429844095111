const SHARED_LOCIZE_NAMESPACES = [
  'account',
  'account_creation',
  'address_types',
  'akyc',
  'cm_common',
  'cm_module',
  'common',
  'countries',
  'datacapture',
  'document_manager',
  'goal_options',
  'homepage',
  'household',
  'identification_dual_source_types',
  'identification_types',
  'le_common',
  'legal_entity',
  'login',
  'nav',
  'onboarding_survey',
  'options',
  'provinces',
  'us_states',
  'relationships',
  'salutation_types',
  'select_options',
];

const FRONTEND_LOCIZE_NAMESPACES = [
  ...SHARED_LOCIZE_NAMESPACES,
  'consent',
  'ips_group',
  'mfa',
  'household',
  'sub_advisor_consent',
];

const BACKEND_LOCIZE_NAMESPACES = [
  ...SHARED_LOCIZE_NAMESPACES,
  'communications',
  'email',
  'ips',
  'ips_fee_schedule',
  'portfolio_category',
  'risk_profile',
];

module.exports = {
  FRONTEND_LOCIZE_NAMESPACES,
  BACKEND_LOCIZE_NAMESPACES,
};
